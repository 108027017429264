/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { v4 } from 'uuid'

// Libraries
import Select from 'react-select'

// Components
import ButtonDefault from '../elements/ButtonDefault'
import Content from './Content'
import Loading from './Loading'

const StyledForm = styled.form`
  position: relative;
`

const ConfirmationMessage = styled(Content)`
  font-size: ${(props) => props.theme.font.size.l};
  text-align: center;
`

const Label = styled.label`
  display: block;
`

const Mandatory = styled.span`
  font-size: ${(props) => props.theme.font.size.xm};
  color: ${(props) => props.theme.color.face.contrast};
  margin-left: 5px;
`

const Input = styled.input`
  width: 100%;
  height: 46px;
  border: 1px solid ${(props) => props.theme.color.face.grey};
  padding: 0 10px;
`

const TextArea = styled.textarea`
  width: 100%;
  height: 146px;
  border: 1px solid ${(props) => props.theme.color.face.grey};
  padding: 10px;
`

const GravityForm = ({ className, id }) => {
  const {
    allGfForm: { edges: gravityData },
  } = useStaticQuery(graphql`
    query {
      allGfForm {
        edges {
          node {
            formId
            slug
            apiURL
            descriptionPlacement
            formFields {
              id
              label
              labelPlacement
              description
              descriptionPlacement
              type
              choices
              content
              errorMessage
              inputMaskValue
              isRequired
              visibility
              cssClass
              placeholder
              size
              defaultValue
              maxLength
            }
            button {
              text
            }
            confirmations {
              message
            }
          }
        }
      }
    }
  `)

  const { node: gfForm } = gravityData.filter(
    ({ node }) => node.formId === id
  )[0]

  const [fields, setFields] = useState({})
  const [form, setForm] = useState(gfForm)
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('')

  useEffect(() => {
    const tempForm = form

    // add submit button as a field
    if (
      tempForm.formFields.filter((item) => item.type === 'submit').length === 0
    ) {
      tempForm.formFields = [
        ...tempForm.formFields,
        {
          formId: v4(),
          type: 'submit',
          text: tempForm.button.text,
        },
      ]
    }

    setForm({
      ...form,
      ...tempForm,
    })
  }, [])

  async function handleOnSubmit(event) {
    event.preventDefault()

    if (status === 'processing') {
      return
    }

    setStatus('processing')

    try {
      const formData = new FormData()

      for (const [key, value] of Object.entries(fields)) {
        formData.append(key, value)
      }

      const request = await fetch(`${form.apiURL}/submissions`, {
        method: 'POST',
        body: formData,
      })

      const response = await request.json()
      if (response.is_valid === true) {
        setStatus('done')

        setMessage(response.confirmation_message)
      } else {
        setStatus('error')
      }
    } catch (error) {
      setStatus('error')
    }
  }

  function handleFieldChange(event) {
    // eslint-disable-next-line prefer-destructuring
    let { value } = event.target || event.value

    if (event.target) {
      if (event.target.type === 'checkbox') {
        value = event.target.checked ? event.target.value : ''
      }

      setFields({
        ...fields,
        [event.target.name]: value,
      })
    } else {
      setFields({
        ...fields,
        [event.name]: event.value,
      })
    }
  }

  if (status === 'done') {
    return <ConfirmationMessage content={message} />
  }

  if (form.formFields) {
    return (
      <StyledForm
        id={`form_${gfForm.formId}`}
        className={className}
        method="post"
        onSubmit={handleOnSubmit}
      >
        {status === 'processing' && <Loading />}
        {form.formFields &&
          form.formFields.map((field, key) => {
            if (Array.isArray(field)) {
              return (
                <div key={key}>
                  {field.map((item, index) => (
                    <FormField
                      key={index}
                      field={item}
                      fields={fields}
                      onChange={handleFieldChange}
                    />
                  ))}
                </div>
              )
            }

            return (
              <div key={key}>
                <FormField
                  field={field}
                  fields={fields}
                  onChange={handleFieldChange}
                />
              </div>
            )
          })}
      </StyledForm>
    )
  }

  console.error('No gravity forms found with id', id)
  return false
}

const TextField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}) => (
  <div className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <Input
      value={value}
      onChange={onChange}
      type="text"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const EmailField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}) => (
  <div className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <Input
      value={value}
      onChange={onChange}
      type="email"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const PhoneField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}) => (
  <div className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <Input
      value={value}
      onChange={onChange}
      type="tel"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const TextAreaField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}) => (
  <div className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <TextArea
      value={value}
      onChange={onChange}
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const CheckboxField = ({
  value,
  onChange,
  field: { id, type, cssClass, choices },
}) => {
  const list = JSON.parse(choices)

  return (
    <div className="form-group">
      {list.map((checkbox, key) => (
        <div key={key} className="form-group__checkboxes">
          <input
            checked={value}
            onChange={onChange}
            type="checkbox"
            id={`input_${id}_${key + 1}`}
            className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
            name={`input_${id}_${key + 1}`}
            value={checkbox.value}
          />
          <label
            htmlFor={`input_${id}_${key + 1}`}
            className="checkbox-content"
          >
            {checkbox.text}
          </label>
        </div>
      ))}
    </div>
  )
}

const StyledSelect = styled.div`
  margin-bottom: 1rem;
`

// Select or Dropdown
const SelectField = ({ field, onChange, value }) => {
  // Populate a options array
  const options = []
  JSON.parse(field.choices).forEach((choice) => {
    options.push({
      value: choice.value,
      label: choice.text,
      type: 'select',
      name: `input_${field.id}`,
    })
  })

  // Custom Select component
  const MySelect = (props) => (
    <Select
      {...props}
      onChange={props.onChange}
      options={props.options}
      placeholder={props.placeholder}
    />
  )

  return (
    <StyledSelect>
      <MySelect
        options={options}
        onChange={onChange}
        value={options.filter((option) => option.value === value)}
        placeholder={field.label}
        classNamePrefix="react-select"
      />
    </StyledSelect>
  )
}

const SubmitButton = ({ field }) => (
  <div>
    <ButtonDefault isCustom>
      <button type="submit">{field.text}</button>
    </ButtonDefault>
  </div>
)

const FileField = ({
  value,
  onChange,
  field: { id, type, label, allowedExtensions, cssClass, placeholder },
}) => {
  function handleChange(file) {
    onChange({
      target: {
        name: `input_${id}`,
        value: file,
      },
    })
  }

  function handleFileUpload(e) {
    const {
      e: {
        target: { files },
      },
    } = e

    for (let i = 0; i < files.length; i += 1) {
      handleChange(files[i])
    }
  }

  function handleFileDrop(e) {
    e.preventDefault()

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === 'file') {
          handleChange(e.dataTransfer.items[i].getAsFile())
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
        handleChange(e.dataTransfer.files[i])
      }
    }
  }

  return (
    <div
      className="form-upload"
      onDrop={handleFileDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <Label htmlFor={`input_${id}`}>{value ? value.name : <>{label}</>}</Label>
      <input
        type="file"
        onChange={handleFileUpload}
        id={`input_${id}`}
        className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
        name={`input_${id}`}
        placeholder={placeholder}
        accept={allowedExtensions}
      />
    </div>
  )
}

export const FormField = ({ field, fields, onChange }) => (
  <>
    {field.type === 'text' && (
      <TextField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'email' && (
      <EmailField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'phone' && (
      <PhoneField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'textarea' && (
      <TextAreaField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'checkbox' && (
      <CheckboxField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'select' && (
      <SelectField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'fileupload' && (
      <FileField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'submit' && <SubmitButton field={field} />}
  </>
)

export default GravityForm
